<template>
  <b-modal
    :id="`modal-add-product-to-cart-${modalKey}`"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header
      class="border border-light-bottom border-light-line border-light-top border-light-line px-2"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="text-dark h6 font-weight-bolder mb-0">
          {{ product.name || "" }}
        </h1>
        <feather-icon
          class="text-dark font-weight-bolder"
          icon="XIcon"
          style="cursor: pointer"
          @click="$bvModal.hide(`modal-add-product-to-cart-${modalKey}`)"
        />
      </div>
    </header>

    <b-row
      v-if="!isDefault"
      class="mt-3 px-2 mx-0 justify-content-between align-items-center"
    >
      <b-col lg="7" class="pr-1 pl-0">
        <h6 class="text-dark font-weight-bolder">Pilih Lokasi SKU</h6>
      </b-col>
      <b-col lg="5" class="px-0">
        <b-form-group class="mb-0 custom__form--input">
          <b-form-select
            v-if="options.length > 0"
            v-model="formPayload.warehouse_uuid"
            :options="options"
            text-field="title"
            placeholder="Pilih Lokasi SKU"
            @input="handleInput"
          />

          <small
            v-if="
              message.warehouse_uuid &&
              Array.isArray(message.warehouse_uuid) &&
              message.warehouse_uuid.length > 0
            "
            class="text-danger"
            >{{ message.warehouse_uuid.join(", ") }}</small
          >
        </b-form-group>
      </b-col>
      <b-col class="px-0" lg="12">
        <hr class="" />
      </b-col>
      <transition name="fade" mode="out-in">
        <b-col v-if="optionsPo.length > 0" lg="12" class="px-0 mb-1">
          <b-form-group class="mb-0 custom__form--input">
            <label>Pilih Nomor PO/SO <span class="text-danger">*</span></label>
            <b-form-select
              v-model="formPayload.po_so_uuid"
              :options="optionsPo"
              text-field="title"
              @change="selectPoId(optionsPo)"
              @input="selectPoId(optionsPo)"
              @load="selectPoId(optionsPo)"
            />
            <small
              v-if="
                message.po_so_id &&
                Array.isArray(message.po_so_id) &&
                message.po_so_id.length > 0
              "
              class="text-danger"
              >{{ message.po_so_id.join(", ") }}</small
            >
          </b-form-group>
        </b-col>
      </transition>
      <transition name="fade" mode="out-in">
        <b-col
          v-if="optionsPo.length > 0 && optionsDeliveryNumber.length > 0"
          lg="12"
          class="px-0"
        >
          <b-form-group class="mb-0 custom__form--input">
            <label
              >Pilih Nomor Surat Jalan <span class="text-danger">*</span></label
            >
            <v-select
              v-model="formPayload.delivery_numbers_uuid"
              multiple
              label="title"
              :options="optionsDeliveryNumber"
            />
            <small
              v-if="
                message.delivery_numbers_uuid &&
                Array.isArray(message.delivery_numbers_uuid) &&
                message.delivery_numbers_uuid.length > 0
              "
              class="text-danger"
              >{{ message.delivery_numbers_uuid.join(", ") }}</small
            >
          </b-form-group>
        </b-col>
      </transition>
    </b-row>

    <b-row
      v-if="isDefault"
      class="px-2 mx-0 justify-content-between align-items-center"
      style="background: #fff4ec; height: 93px"
    >
      <div>
        <h6 class="text-dark">Lokasi SKU belum di set</h6>
        <small>
          Pilih lokasi SKU lalu lanjutkan proses penambahan produk
        </small>
      </div>
      <b-button
        id="button--set__warehouse"
        variant="dark"
        @click="
          $bvModal.show('modal-add-product'),
            $bvModal.hide(`modal-add-product-to-cart-${modalKey}`)
        "
      >
        Set Lokasi
      </b-button>
    </b-row>

    <div class="container px-0">
      <b-row class="px-2 mx-0 mt-2">
        <b-col
          lg="7"
          md="6"
          class="px-0 d-flex justify-content-center flex-column"
        >
          <h6 class="text-dark font-weight-bolder mb-0">
            Jumlah Penjualan <span class="text-danger">*</span>
          </h6>
          <small v-if="!isDefault" class="text-dark text-darken-5">
            Stok Tersedia : {{ stock || "-" }} {{ product.unit || "" }}
          </small>
        </b-col>
        <b-col lg="5" md="6" class="px-0">
          <div
            id="tour-qty"
            class="spinner__button spinner__button--addproduct"
          >
            <div
              id="decrease"
              class="value-button text-dark font-weight-bolder"
              value="Decrease Value"
              @click="decreaseValue"
            >
              -
            </div>
            <input
              id="qty"
              class="qty text-dark font-weight-bolder"
              type="text"
              :value="formPayload.qty"
              @input="getQty"
              @change="getQty"
            />
            <div
              id="increase"
              class="value-button text-dark font-weight-bolder"
              value="Increase Value"
              @click="increaseValue"
            >
              +
            </div>
          </div>
          <small
            v-if="
              message.qty &&
              Array.isArray(message.qty) &&
              message.qty.length > 0
            "
            class="text-danger"
            >{{ message.qty.join(", ") }}</small
          >
        </b-col>
        <!-- Harga Barang -->
        <b-col class="px-0 mt-2" lg="12">
          <div class="custom__form--input">
            <label for="harga-barang">
              Harga Barang <span class="text-danger font-weight-bolder">*</span>
            </label>
            <!-- <div class="input-group mb-3" id="tour-price">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  Rp
                </div>
              </div>
              <span class="input-group-prepend input-group-text">Rp</span>
              <input type="text" class="custom__input" placeholder="Recipient's username" aria-label="Recipient's username" @input="getRupiah" @change="getRupiah">
              <span class="input-group-append input-group-text">Min Rp {{ product.minimum_price | formatAmount }}</span>
            </div> -->

            <div
              id="tour-price"
              role="group"
              class="input-group justify-content-between border-10"
              style="background: #eff1f5"
            >
              <div class="d-flex w-100 justify-content-between">
                <div class="d-flex">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    id="harga-barang"
                    ref="harga-barang"
                    :value="formPayload.price"
                    type="text"
                    class="size24 custom__input pl-2"
                    @input="getRupiah"
                    @change="getRupiah"
                  />
                </div>
                <div class="input-group-append">
                  <div class="input-group-text">
                    Min {{ product.minimum_price | formatAmount }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <b-input-group
              id="tour-price"
              prepend="Rp"
              :append="`Min Rp ${minPriceFormatted}`"
            >
              <input
                id="harga-barang"
                ref="harga-barang"
                :value="formPayload.price"
                type="text"
                class="size24 custom__input pl-2"
                style="width: 65%;"
                @input="getRupiah"
                @change="getRupiah"
              >
            </b-input-group> -->
          </div>
          <small
            v-if="
              message &&
              Array.isArray(message) &&
              message.length > 0 &&
              formPayload.price < min_price
            "
            class="text-danger"
            >{{ message.join(", ") }}</small
          >
        </b-col>
        <b-col class="px-0" lg="12">
          <small class="text-dark text-darken-5">
            Harga Jual Terakhir: {{ product.latest_order_price | formatAmount }}
          </small>
        </b-col>
        <b-col lg="12" class="mt-1 px-0" v-if="checkPermission('tambah diskon keranjang')">
          <b-card
            class="border-8 p-0 mb-0 w-100"
            style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04)"
            no-body
            
          >
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                id="tour-discount"
                class="d-flex align-items-center justify-content-between px-2 py-1 w-100"
                @click="addDiscount"
              >
                <div class="d-flex align-items-center">
                  <div class="rounded__frame">
                    <b-img
                      width="17"
                      :src="require('@/assets/images/icons/discount.svg')"
                    />
                  </div>
                  <h6 class="text-primary font-weight-bolder mb-0">
                    Masukkan diskon tambahan % atau Rp
                  </h6>
                </div>
                <feather-icon
                  class="text-dark font-weight-bolder"
                  size="20"
                  icon="PlusIcon"
                />
              </div>
            </div>
            <b-row v-if="discounts.length > 0" class="mt-1">
              <b-col cols="12">
                <hr />
              </b-col>
              <b-col
                v-for="(discount, index) in discounts"
                :key="index"
                cols="12"
                class="mb-1"
              >
                <div class="d-flex px-2 align-items-center">
                  <h6 class="mb-0 w-50">
                    {{ index + 1 }}. Diskon
                    {{ discount.type === "fixed" ? "Rp" : "%" }}
                  </h6>
                  <span class="form-control" style="background-color: #f1f2f5">
                    {{ discount.discount | formatInvoice }}
                  </span>
                  <!-- <b-form-input
                    v-money="money"
                    class="w-100"
                    :value="discount.discount"
                    readonly
                    disabled
                  /> -->
                  <div
                    id="button--edit__discount"
                    class="align-self-center text-right ml-1"
                    role="button"
                    @click="editDiscount(index, discount)"
                  >
                    <feather-icon
                      size="24"
                      icon="EditIcon"
                      class="text-primary fw-bold-800"
                    />
                  </div>
                  <div
                    id="button--delete__discount"
                    class="align-self-center text-right ml-1"
                    role="button"
                    @click="deleteDiscount(index)"
                  >
                    <feather-icon
                      size="24"
                      icon="Trash2Icon"
                      class="text-danger fw-bold-800"
                    />
                  </div>
                </div>
                <hr class="mb-0 mt-1" />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="floating__button">
      <b-card no-body class="card__discount mb-0 p-0">
        <div class="d-flex align-items-center justify-content-between">
          <div
            class="d-flex align-items-center justify-content-between px-2 py-1 w-100"
          >
            <div class="d-flex align-items-center">
              <div class="rounded__frame">
                <b-img
                  width="17"
                  :src="require('@/assets/images/icons/bonus.svg')"
                />
              </div>
              <h6 class="text-primary font-weight-bolder mb-0">
                Centang untuk dijadikan bonus
              </h6>
            </div>
            <b-form-checkbox
              id="tour-bonus"
              v-model="formPayload.is_bonus"
              class="custom-control-primary"
              name="bonus"
              value="1"
              unchecked-value="0"
              switch
              inline
            />
            <small
              v-if="
                message.is_bonus &&
                Array.isArray(message.is_bonus) &&
                message.is_bonus.length > 0
              "
              class="text-danger"
              >{{ message.is_bonus.join(", ") }}</small
            >
          </div>
        </div>
      </b-card>
      <div class="d-flex border-top">
        <div class="p-1 p-md-2 p-lg-2 w-100 bg-white">
          <small
            v-if="formPayload.is_bonus == 1"
            class="text-dark text-darken-4 size14"
            :class="{ 'text-line-through': formPayload.is_bonus == 1 }"
            >{{ formPayload.price | formatAmount }}</small
          >
          <h1
            v-if="formPayload.is_bonus == 1"
            class="h6 size20 font-weight-bolder mb-0"
          >
            Rp 0,00
          </h1>
          <h1 v-else class="h6 size20 font-weight-bolder mb-0">
            {{ total | formatAmount }}
          </h1>
        </div>
        <div
          id="button--create__item--to__cart"
          class="p-1 p-md-2 p-lg-2 btn-save"
          role="button"
          :disabled="isLoading"
          @click="createItemToCart(), (message = '')"
        >
          <h1 class="h6 size16 font-weight-bolder mb-0">
            <b-spinner v-if="isLoading" class="mr-1" variant="primary" />
            Simpan
          </h1>
        </div>
      </div>
    </div>
    <app-tour :name-tour="'cashierTour2'" :steps="steps" />
    <ModalAddtionalInputDiscount :form-data="discount" :is-stock="false" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  VBToggle,
  BCard,
  BImg,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { preformatFloat, checkPermission } from "@/auth/utils";
import Cleave from "cleave.js";
import ModalAddtionalInputDiscount from "@/components/Cashier/Modal/AdditionalInputDiscount.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import AppTour from "@core/components/app-tour/AppTour.vue";

export default {
  components: {
    BModal,
    BCard,
    BImg,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BSpinner,
    vSelect,
    AppTour,
    ModalAddtionalInputDiscount,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    cleave: {
      inserted: (el, binding) => {
        // eslint-disable-next-line no-param-reassign
        el.cleave = new Cleave(el, binding.value || {});
      },
      update: (el) => {
        const event = new Event("input", { bubbles: true });
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          el.value = el.cleave.properties.result;
          el.dispatchEvent(event);
        }, 100);
      },
    },
  },
  setup() {
    return {
      preformatFloat,
      checkPermission,
    };
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    formData: {
      type: Object,
    },
    result: {
      type: Object,
    },
    createItemToCart: {
      type: Function,
    },
    messages: {
      type: "",
    },
    loading: {
      type: Boolean,
    },
    modalKey: {
      type: String,
    },
    isDefault: {
      type: Boolean,
    },
    stockWarehouse: {
      type: Number,
    },
    typeWarehouses: {
      type: String,
    },
  },
  data() {
    return {
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: "",
        product_uuid: "",
        warehouse_uuid: "",
        qty: 0,
        price: 0,
        discounts: [],
        is_bonus: 0,
      },
      discounts: [],
      discount: {},
      product: {},
      is_bonus: true,
      is_ritel: true,
      options: [
        {
          value: "",
          title: "Pilih Lokasi SKU",
          stock: "",
        },
      ],
      typeWarehouse: "",
      optionsPo: [],
      optionsDeliveryNumber: [],
      isLoading: false,
      stock: 0,
      min_price: 0,
      outputDiscount: 0,
      total: 0,
      message: "",
      steps: [
        {
          target: "#tour-qty",
          content: `
                    <div class="text-white size16 mb-1">Atur jumlah barang yang dijual.</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Tekan tombol “+” untuk menambah, “-” untuk mengurangi, atau ketik langsung spesifik jumlah yang dibutuhkan.</div`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
        {
          target: "#tour-price",
          content: `
                    <div class="text-white size16 mb-1">Atur nominal harga jual.</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Ketik langsung pada kolom harga jual untuk mengatur nominal harga jual.</div`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
        {
          target: "#tour-discount",
          content: `
                    <div class="text-white size16 mb-1">Tambahkan diskon.</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Anda dapat menambahkan diskon dalam nominal atau persentase dari harga jual.</div`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
        {
          target: "#tour-bonus",
          content: `
                    <div class="text-white size16 mb-1">Jadikan sebagai hadiah untuk pelanggan.</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Centang tombol di bawah, agar produk ini sebagai hadiah/bonus dengan harga Rp0,-</div`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
      ],
      qty: 0,
    };
  },
  computed: {
    minPriceFormatted() {
      return this.min_price > 0
        ? Number(this.min_price).toLocaleString().replace(/,/g, ".")
        : 0;
    },
    totalPrice() {
      return this.total > 0
        ? Number(this.total).toLocaleString().replace(/,/g, ".")
        : 0;
    },
  },
  watch: {
    "formPayload.warehouse_uuid"(newVal, oldVal) {
      this.selectOnChange(this.options);
    },
    "$store.state.cart.detailCart": {
      handler(value) {
        this.message = "";
        this.options = [];
        this.optionsPo = [];
        this.optionsDeliveryNumber = [];
        let po_so = [];

        for (
          let index = 0;
          index < value.item?.stock_in_warehouse.length;
          index++
        ) {
          const element = value.item?.stock_in_warehouse[index];
          this.options.push({
            value: element.uuid,
            title: `${element.name} (Stok: ${element.stock})`,
            stock: element.stock < 0 ? "" : element.stock,
            type: element.type,
            po_so: element.po_so || null,
          });

          if (element.uuid === value.product.warehouse?.uuid) {
            this.stock = element.stock;
          }
        }

        if (this.modalKey === "product") {
          // eslint-disable-next-line no-plusplus
          for (
            let index = 0;
            index < value.product?.delivery_numbers.length;
            index++
          ) {
            const element = value.product?.delivery_numbers[index];
            this.optionsDeliveryNumber.push({
              title: element.delivery_number_note,
              label: element.uuid,
            });
          }

          for (
            let index = 0;
            index < value.item?.stock_in_warehouse.length;
            index++
          ) {
            const element = value.item?.stock_in_warehouse[index];
            if (value.product.supplier?.uuid === element.uuid) {
              po_so = element.po_so;
            }
          }

          this.optionsPo = po_so.map((supplier) => ({
            value: supplier.uuid,
            title: supplier.po_so_id,
            delivery_numbers: supplier.delivery_numbers,
          }));
        }

        if (value.product.warehouse !== null) {
          this.$emit("typeWarehouse", "warehouse");
        } else {
          this.$emit("typeWarehouse", "supplier");
        }

        this.$store.commit("cart/setItemUuid", value.product?.uuid);

        this.formPayload = {
          delivery_numbers_uuid:
            value.product?.delivery_numbers.map((element) => ({
              title: element.delivery_number_note,
              label: element.uuid,
            })) || [],
          product_uuid: value.product?.product?.uuid,
          po_so_uuid: value.product?.po_so?.uuid || null,
          warehouse_uuid: value.product?.warehouse
            ? value.product?.warehouse?.uuid
            : value.product?.supplier?.uuid,
          qty: value.product?.qty || "0",
          price: value.product?.sub_price,
          discounts: value.product?.discounts,
          is_bonus: value.product?.is_bonus === false ? 0 : 1,
        };

        this.min_price = value.item?.minimum_price;
        this.product.name = value.item?.name;
        this.product.stock = value.item?.stock;
        this.product.unit = value.item?.unit;
      },
      deep: true,
    },
    messages(value) {
      this.message = value;
    },
    loading(value) {
      this.isLoading = value;
    },
    discounts(value) {
      this.summaryDiscount();
    },
    "$store.state.cashier.discounts": {
      handler(value) {
        if (this.modalKey === "product") {
          if (this.$store.state.cashier.isEdit) {
            this.discounts.splice(this.$store.state.cashier.indexDiscounts, 1);
            this.discounts.splice(this.$store.state.cashier.indexDiscounts, 0, {
              type: value.type,
              discount: value.discount,
            });
            this.summaryDiscount();
          } else {
            this.discounts.push({
              type: value.type,
              discount: value.discount,
            });
            this.summaryDiscount();
          }
        }
        if (this.modalKey === "search") {
          if (this.$store.state.cashier.isEdit) {
            this.discounts.splice(this.$store.state.cashier.indexDiscounts, 1);
            this.discounts.splice(this.$store.state.cashier.indexDiscounts, 0, {
              type: value.type,
              discount: value.discount,
            });
            this.summaryDiscount();
          } else {
            this.discounts.push({
              type: value.type,
              discount: value.discount,
            });
            this.summaryDiscount();
          }
        }
        this.summaryDiscount();
      },
      deep: true,
    },
    result: {
      handler(value) {
        this.options = [];
        this.optionsPo = [];
        this.optionsDeliveryNumber = [];
        this.min_price = value.minimum_price;
        this.product = value;
        // let poSo = []

        // // eslint-disable-next-line no-plusplus
        // for (let index = 0; index < value.stock_in_warehouse.length; index++) {
        //   const element = value.stock_in_warehouse[index]
        //   poSo = element.po_so
        //   // if (value.product.supplier?.uuid === element.uuid) {
        //   // }
        // }

        // this.optionsPo = poSo.map(supplier => ({
        //   value: supplier.uuid,
        //   title: supplier.po_so_id,
        //   delivery_numbers: supplier.delivery_numbers,
        // }))

        // this.selectPoId(this.optionsPo)

        if (!this.isDefault && value && value.stock_in_warehouse) {
          // Populate the options array with data from stock_in_warehouse
          this.options = value.stock_in_warehouse.map((element, index) => {
            if (index === 0) {
              this.formPayload.warehouse_uuid = element.uuid; // Set default warehouse UUID
            }
            return {
              value: element.uuid,
              title: `${element.name} (Stok: ${element.stock})`,
              stock: element.stock < 0 ? "" : element.stock,
              type: element.type,
              po_so: element.po_so || null,
            };
          });
          this.selectOnChange(this.options);
        }
      },
      deep: true,
    },
    formData: {
      handler(value) {
        this.formPayload = value;
        this.discounts = value.discounts || [];
        this.qty =
          typeof value.qty === "number"
            ? parseFloat(value.qty).toString().replace(".", ",")
            : value.qty;
        this.formPayload.qty = this.qty;
        if (this.$refs["harga-barang"]) {
          setTimeout(() => {
            this.$refs["harga-barang"].value = this.formatRupiah(
              this.$refs["harga-barang"].value,
              ""
            );
            this.formPayload.price = this.$refs["harga-barang"].value;
          }, 1000);
        }
      },
      deep: true,
    },
    optionsPo(value) {
      if (value.length === 0) {
        this.formPayload = {
          ...this.formPayload,
          delivery_numbers_uuid: [],
          po_so_uuid: "",
        };
      }
    },
    formPayload: {
      handler(value) {
        this.summaryDiscount();
        this.$emit("payload", value);
      },
      deep: true,
    },
    stockWarehouse(value) {
      this.stock = value < 0 ? "" : value;
    },
    typeWarehouses(value) {
      this.typeWarehouse = value;
    },
  },
  destroyed() {
    this.message = "";
    this.messages = "";
    this.qty = 0;
    this.discounts = "";
    this.formPayload = {
      delivery_numbers_uuid: [],
      po_so_uuid: "",
      product_uuid: "",
      warehouse_uuid: "",
      qty: 0,
      price: 0,
      discounts: [],
      is_bonus: 0,
    };
  },
  methods: {
    getQty() {
      const qty = document.getElementById("qty");
      qty.value = this.formatRupiah(qty.value, "");
      this.formPayload.qty = qty.value;
      this.summaryDiscount();
    },
    getRupiah() {
      const rupiah = document.getElementById("harga-barang");
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayload.price = rupiah.value;
      this.summaryDiscount();
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    editDiscount(index, discount) {
      this.$store.commit("cashier/setIndexDiscounts", index);
      this.discount = discount;
      this.$store.commit("cashier/setIsAddDiscount", true);
      this.$store.commit("cashier/SET_IS_EDIT", true);
      this.$bvModal.show("modal-additional-input-discount");
    },
    deleteDiscount(index) {
      this.discounts.splice(index, 1);
      this.summaryDiscount();
    },
    addDiscount() {
      this.discount = {
        type: "percent",
        discount: 0,
      };
      this.$store.commit("cashier/setIndexDiscounts", false);
      this.$store.commit("cashier/setIsAddDiscount", true);
      this.$store.commit("cashier/SET_IS_EDIT", false);
      this.$bvModal.show("modal-additional-input-discount");
    },
    summaryDiscount() {
      try {
        let { price, qty } = this.formPayload;
        price =
          typeof price === "number"
            ? price
            : parseFloat(this.preformatFloat(price));
        qty =
          typeof qty === "number" ? qty : parseFloat(this.preformatFloat(qty));
        // eslint-disable-next-line no-restricted-syntax, no-plusplus
        for (let index = 0; index < this.discounts.length; index++) {
          const element = this.discounts[index];
          if (element.type === "fixed" && element.discount > 0) {
            // eslint-disable-next-line radix
            const fixedPrice = element.discount;
            price -= fixedPrice;
          } else if (element.type === "percent" && element.discount > 0) {
            // eslint-disable-next-line no-mixed-operators, radix, operator-assignment
            price = ((100 - element.discount) / 100) * price;
          }
        }
        this.total = price * qty || 0;
      } catch (error) {
        console.log(error);
      }
    },
    selectPoId(event) {
      this.optionsDeliveryNumber = [];
      event.forEach((element) => {
        if (element.value === this.formPayload.po_so_uuid) {
          // eslint-disable-next-line no-plusplus
          for (
            let index = 0;
            index < element.delivery_numbers.length;
            index++
          ) {
            const item = element.delivery_numbers[index];
            this.optionsDeliveryNumber.push({
              title: item.delivery_number_note,
              label: item.uuid,
            });
          }
        }
      });
    },
    handleInput(event) {
      this.selectOnChange(event); // Handle the event
    },
    selectOnChange(event) {
      if (!event || !Array.isArray(event)) {
        return; // Exit if event is null or not an array
      }

      this.optionsPo = [];
      this.optionsDeliveryNumber = [];

      event.forEach((element) => {
        if (element.value === this.formPayload.warehouse_uuid) {
          this.typeWarehouse = element.type;
          this.$emit("typeWarehouse", element.type);

          if (element.po_so) {
            element.po_so.forEach((item) => {
              this.optionsPo.push({
                value: item.uuid,
                title: item.po_so_id,
                delivery_numbers: item.delivery_numbers,
              });
            });
          }
          this.stock = element.stock;
        }
      });
      setTimeout(() => {
        const options = this.getUniqueListBy(this.optionsPo, "value");
        this.optionsPo = options;
      }, 500);
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    changeValue() {
      const qty = document.getElementById("qty").value;
      this.formPayload.qty = parseFloat(qty);
      this.summaryDiscount();
    },
    increaseValue() {
      this.qty =
        typeof this.qty === "number"
          ? this.qty
          : parseFloat(this.preformatFloat(this.qty));
      this.qty = +(this.qty + 0.1).toFixed(1);
      this.formPayload.qty = this.formatRupiah(
        this.qty.toString().replace(".", ","),
        ""
      );
      document.getElementById("qty").value = this.formPayload.qty;
      this.summaryDiscount();
    },
    decreaseValue() {
      this.qty =
        typeof this.qty === "number"
          ? this.qty
          : parseFloat(this.preformatFloat(this.qty));
      if (this.qty > 0) {
        this.qty = +(this.qty - 0.1).toFixed(1);
      }
      this.formPayload.qty = this.formatRupiah(
        this.qty.toString().replace(".", ","),
        ""
      );
      this.summaryDiscount();
    },
    isNumberKey(evt) {
      // eslint-disable-next-line no-restricted-globals
      const charCode = evt.which ? evt.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

#modal-edit-product,
#modal-edit-search {
  .grid-container .grid-item.option-number:last-child {
    border-radius: 0px 16px 0px 0px;
  }
}

#modal-add-product-to-cart-product,
#modal-add-product-to-cart-search {
  .modal-dialog {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    // width: 42vw;
    max-width: 42vw;
    @media screen and (min-height: $tablet-min-height) and (max-height: $tablet-max-height) and (orientation: landscape) {
      max-width: 42vw;
    }
    @media screen and (max-width: 576px) and (orientation: landscape) {
      max-width: 42vw;
    }

    .modal-content {
      height: 100vh;
      overflow-y: scroll;

      .modal-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        .floating__button {
          position: sticky;
          width: 100%;
          bottom: 0;

          > div:hover {
            cursor: pointer;
          }

          .btn-save {
            display: flex;
            align-items: center;
            background: #45b6ab;
            justify-content: center;
            width: 17.576rem;

            h1 {
              color: #ffffff !important;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        .custom__form--input {
          .form-group {
            margin-bottom: 0;
          }

          label {
            font-size: 14px;
            font-weight: 600;
            color: $dark;
          }

          .form-control {
            background: $light--1;
            border: none;
            box-sizing: border-box;
            border-radius: 8px;
            height: 53px;
            color: $dark;
            font-weight: 600;
            font-size: 14px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        .vs__dropdown-toggle {
          background: $light--background;
          border-radius: 8px;
          border: none;
          height: 53px;

          .vs__actions {
            padding: 4px 14px 0 3px;
          }

          .vs__search,
          .vs__search:focus {
            margin: 0;
          }
        }

        .spinner__button.spinner__button--addproduct {
          height: 53px;
        }

        header {
          padding-top: 2rem !important;
          padding-bottom: 2rem !important;
        }

        .input-group {
          input {
            background: $light--background;
            width: unset;
          }

          .input-group-append {
            .input-group-text {
              border: 1px solid $light--background;
              background-color: $light--background;
              color: $light--description;
              border-radius: 8px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $light--background;
              background-color: $light--background;
              color: $light--description;
              border-radius: 8px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }
}
</style>
